#whatsapp-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-btn {
  width: 50px;
  height: 50px;
  background-color: #25D366;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: jump 3s ease-in-out infinite;
}

.whatsapp-icon {
  z-index: 2;
}

.whatsapp-btn:hover, .whatsapp-btn:focus, .whatsapp-btn.clicked {
  background-color: #128C7E;
  box-shadow: 0px 4px 15px rgba(37, 211, 102, 0.4);
  animation: none;
}

@keyframes jump {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
