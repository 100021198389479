.gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .gallery-item img {
    width: 100%;
    height: 300px; /* Adjust this value as needed */
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .gallery-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 32, 32, 0.493); /* Light red with 70% opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .gallery-item-overlay {
    opacity: 1;
  }
  
  .gallery-item:hover img {
    transform: scale(1.1);
  }
  
  .overlay-content {
    text-align: center;
    color: white;
  }
  
  .overlay-content h3 {
    margin-bottom: 15px;
    color: white;

  }
  
/* Gallery icon as a circular play button */
.gallery-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease;
}

.gallery-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid red; /* The triangle color */
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  transform: translate(-50%, -50%);
}

.gallery-icon:hover {
  background-color: red;
}

.gallery-icon:hover::before {
  border-left-color: white; /* Change play icon to white on hover */
}
