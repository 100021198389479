.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*scroll up*/

#back-top button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 48px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#back-top button:hover {
  background-color: #8B0000;
}

/*nav bar*/
.header-area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.5s ease;
}

.header-area.scrolled {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), #8B0000);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-100%);
  animation: slideDown 0.5s forwards;
}

@keyframes slideDown {
  to {
    transform: translateY(0);
  }
}

.main-header {
  padding: 15px 0;
}

#navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

#navigation li {
  margin-left: 20px;
}

#navigation li a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.scrolled #navigation li a {
  color: #fff;
}

#navigation li a:hover {
  color: #FF0000;
}

.logo img {
  transition: all 0.3s ease;
}

.scrolled .logo img {
  filter: brightness(0) invert(1);
}

.icon-circle {
  width: 70px;
  height: 70px;
  border-radius: 45%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle i {
  color: white;
}
.myhover a:hover{
  color: #FF0000;
}